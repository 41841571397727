import { ref, onMounted, onUnmounted } from 'vue';
import { projectFirestore } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import store from '../store';

export default function useWhiteLabel() {
  const whiteLabelConfig = ref(null);
  const isLoading = ref(true);
  const error = ref(null);
  let isComponentMounted = false;

  const getTestDomain = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('testDomain');
  };

  const getSubdomain = () => {
    // First check for test domain parameter
    const testDomain = getTestDomain();
    if (testDomain) {
      // If test domain includes full domain, extract subdomain
      if (testDomain.includes('.')) {
        return testDomain.split('.')[0];
      }
      return testDomain;
    }

    const hostname = window.location.hostname;
    
    // For development environment and testing domain
    if (hostname === 'localhost' || 
        hostname === 'tbdevdash.web.app' || 
        hostname.startsWith('dashboard.')) {
      isLoading.value = false; // Immediately set loading to false for development
      return null;
    }
    
    // Extract subdomain from hostname
    const parts = hostname.split('.');
    if (parts.length > 2) {
      return parts[0];
    }
    
    return null;
  };

  const loadWhiteLabelConfig = async () => {
    if (!isComponentMounted) return;
    
    const subdomain = getSubdomain();
    const testDomain = getTestDomain();

    // If we're in development or no subdomain, set default config and exit
    if (!subdomain) {
      whiteLabelConfig.value = {
        theme: {
          useSecondaryAsBackground: false,
          colors: {
            primary: '#CA8A04',
            secondary: '#ffffff'
          }
        }
      };
      isLoading.value = false;
      return;
    }

    try {
      // If test domain parameter includes full domain, use it directly
      const domain = testDomain?.includes('.') 
        ? testDomain 
        : `${subdomain}.tintbolt.com`;
      
      const docRef = doc(projectFirestore, 'whiteLabel', domain);
      
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists() && isComponentMounted) {
        const data = docSnap.data();
        store.state.whiteLabelClient = data;
        store.state.whiteLabelClient.subdomain = domain.split('.')[0];
        whiteLabelConfig.value = data;
      } else {
        // Set default config if no white label config found
        whiteLabelConfig.value = {
          theme: {
            useSecondaryAsBackground: false,
            colors: {
              primary: '#CA8A04',
              secondary: '#ffffff'
            }
          }
        };
      }
    } catch (err) {
      console.error('Error loading white label config:', err);
      if (isComponentMounted) {
        error.value = err.message;
      }
    } finally {
      if (isComponentMounted) {
        isLoading.value = false;
      }
    }
  };

  onMounted(() => {
    isComponentMounted = true;
    loadWhiteLabelConfig();
  });

  onUnmounted(() => {
    isComponentMounted = false;
  });

  return {
    whiteLabelConfig,
    isLoading,
    error,
    loadWhiteLabelConfig,
    getSubdomain
  };
} 