<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto text-black">
        <div
          class="flex h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-3xl sm:p-6"
            >
              <div>
                <div
                  class="mx-auto w-full flex h-12 items-center justify-center"
                >
                  Click the playlist button in the video for more tutorials

                  <YouTubePlaylistIcon class="h-7 w-7" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <YouTubePlayer
                    class="video-container"
                    :videoId="videoId"
                    :playlistId="playlistId"
                    :width="width"
                    :height="height"
                    :autoplay="autoplay"
                    :mute="mute"
                    :controls="controls"
                  />
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                  @click="closeDialog"
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, watchEffect, computed, watch } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import store from "../../store";
import YouTubePlayer from "../YouTubePlayer.vue";
import YouTubePlaylistIcon from "../UI Components/Icons/YouTubePlaylistIcon.vue";

const videoId = ref("");
const playlistId = ref("PL6xwoteAXUi71tAvk9qgezKlwYoiQztd0");
const width = ref(560);
const height = ref(315);
const autoplay = ref(false);
const mute = ref(false);
const controls = ref(true);

// Use computed property to handle both state variables
const open = computed(() => store.state.videoOpen || store.state.tutorialsOpen || false);

function closeDialog() {
  // Reset both state variables to ensure the dialog closes properly
  store.state.videoOpen = false;
  store.commit('SET_TUTORIALS_OPEN', false);
  console.log("Tutorial video closed");
}

// Watch for changes to videoOpen state
watch(() => store.state.videoOpen, (newValue) => {
  if (newValue) {
    console.log("Tutorial video opened via videoOpen state");
  }
});

// Watch for changes to tutorialsOpen state
watch(() => store.state.tutorialsOpen, (newValue) => {
  if (newValue) {
    console.log("Tutorial video opened via tutorialsOpen state");
  }
});

// Legacy code - can be removed if not used elsewhere
const isOpen = ref(false);
const closeModal = () => {
  isOpen.value = false;
  store.commit('SET_TUTORIALS_OPEN', false);
  store.state.videoOpen = false;
};
</script>

<style scoped>
.video-container {
  width: 100%;
  aspect-ratio: 16/9;
}
</style>
