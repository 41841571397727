<template>
  <BlankLoader />
  <router-view />
  <VehicleManager 
    v-if="isVehicleManagerOpen" 
    :is-open="isVehicleManagerOpen" 
    @close="closeVehicleManager"
    @updated="handleVehicleUpdated"
  />
  <TutorialPlaylist />
  <IntegrationSlideOver v-if="isLocalhost" />
  <!-- <Footer /> -->
</template>

<script setup>
import BlankLoader from "./components/loadingScreen/blankLoader.vue";
import Footer from "./components/Footer.vue";
import useWhiteLabel from './composables/useWhiteLabel';
import useSecurityProtocols from './composables/useSecurityProtocols'
import { onMounted, watch, ref, provide, computed } from 'vue';
import { useStore } from 'vuex';
import VehicleManager from './components/Modals/VehicleManager.vue';
import TutorialPlaylist from './components/Modals/TutorialPlaylist.vue';
import IntegrationSlideOver from './components/BackOffice/Integrations/IntegrationSlideOver.vue';

const store = useStore();
const isVehicleManagerOpen = ref(false);

const { whiteLabelConfig, isLoading } = useWhiteLabel();
const { fetchSecuritySettings } = useSecurityProtocols()

// Computed property to determine if we're in development environment
const isInDevelopment = computed(() => {
  return window.location.hostname === "localhost" || 
         window.location.hostname.includes("dev") ||
         window.location.hostname.includes("staging");
});

// Computed property to check if we're specifically on localhost
const isLocalhost = computed(() => {
  return window.location.hostname === "localhost";
});

// Function to open vehicle manager
const openVehicleManager = () => {
  console.log("Opening vehicle manager");
  isVehicleManagerOpen.value = true;
};

// Function to close vehicle manager
const closeVehicleManager = () => {
  console.log("Closing vehicle manager");
  isVehicleManagerOpen.value = false;
};

// Handle when vehicles are updated
const handleVehicleUpdated = () => {
  // Notify relevant components that vehicles data has been updated
  store.commit('SET_VEHICLES_UPDATED', true);
};

// Provide the vehicle manager functions to all components
provide('vehicleManager', {
  open: openVehicleManager,
  close: closeVehicleManager,
  isOpen: isVehicleManagerOpen
});

onMounted(async () => {
  // Fetch initial security settings
  if (store.state.currentUser) {
    await fetchSecuritySettings(true) // Force initial fetch
  }
})

// Watch for user changes to refetch security settings
watch(() => store.state.currentUser, async (newUser) => {
  if (newUser) {
    await fetchSecuritySettings(true)
  }
})
</script>
