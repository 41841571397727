import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import store from '../store'
import bcrypt from 'bcryptjs'

const useSecurityProtocols = () => {
  const lastValidated = ref(localStorage.getItem('lastValidated') ? parseInt(localStorage.getItem('lastValidated')) : null)
  const defaultTimeout = 30 // 30 minutes default
  const CACHE_DURATION = 5 * 60 * 1000 // 5 minutes cache

  const fetchSecuritySettings = async (force = false) => {
    
    const currentTime = new Date().getTime()
    const lastFetched = store.state.securitySettings.lastFetched

    // Return cached data if within cache duration and not forced
    if (!force && lastFetched && (currentTime - lastFetched) < CACHE_DURATION) {
      return store.state.securitySettings
    }

    try {
      const userDoc = await getDoc(doc(projectFirestore, 'users', store.state.currentUser.uid))
      const userData = userDoc.data()
      
      const settings = {
        sections: userData?.securityProtocols?.sections || {},
        sessionTimeout: userData?.securityProtocols?.sessionTimeout ?? defaultTimeout,
        hasPassword: !!userData?.securityProtocols?.password,
        password: userData?.securityProtocols?.password // Store hashed password
      }

      store.commit('SET_SECURITY_SETTINGS', settings)
      return settings
    } catch (error) {
      console.error('❌ Error fetching security settings:', error)
      return store.state.securitySettings
    }
  }

  const getSessionTimeout = async () => {
    const settings = await fetchSecuritySettings()
    return settings.sessionTimeout
  }

  const isValidSession = async () => {
    
    if (!lastValidated.value) {
      return false
    }
    
    const now = new Date().getTime()
    const timeout = await getSessionTimeout()

    // If timeout is 0, always require re-authentication
    if (timeout === 0) {
      clearSession()
      return false
    }
    
    const timeoutMs = timeout * 60 * 1000
    const isValid = (now - lastValidated.value) < timeoutMs
    return isValid
  }

  const setValidSession = () => {
    const timestamp = new Date().getTime()
    lastValidated.value = timestamp
    localStorage.setItem('lastValidated', timestamp.toString())
  }

  const clearSession = () => {
    lastValidated.value = null
    localStorage.removeItem('lastValidated')
  }

  const checkSectionSecurity = async (sectionName) => {
    const settings = await fetchSecuritySettings()
    const requiresAuth = settings.sections[sectionName.toLowerCase()] || false
    return requiresAuth
  }

  const hasSecurityPassword = async () => {
    const settings = await fetchSecuritySettings()
    return settings.hasPassword
  }

  const validatePassword = async (password) => {
    const settings = await fetchSecuritySettings()
    
    if (!settings.password) {
      return false
    }

    const isValid = await bcrypt.compare(password, settings.password)
    
    if (isValid) {
      setValidSession()
    }

    return isValid
  }

  return {
    isValidSession,
    setValidSession,
    clearSession,
    checkSectionSecurity,
    hasSecurityPassword,
    validatePassword,
    getSessionTimeout,
    fetchSecuritySettings
  }
}

export { useSecurityProtocols }
export default useSecurityProtocols 