<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main class="bg-white text-gray-900">
    <TransitionRoot as="template" :show="integrationOpenState">
      <Dialog as="div" class="relative z-40">
        <div class="fixed inset-0" />

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div
              class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
            >
              <TransitionChild
                as="template"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from="translate-x-full"
                enter-to="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from="translate-x-0"
                leave-to="translate-x-full"
              >
                <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                  <div
                    class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div class="px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          Integrations
                        </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-yellow-500"
                            @click="close"
                          >
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Main -->
                    <div class="divide-y divide-gray-200">
                      <!-- Integration Selection View -->
                      <div v-if="!activeIntegration" class="pb-6">
                        <div class="m-4 p-3 text-black rounded-md">
                          <h3 class="text-lg font-medium mb-4">Available Integrations</h3>
                          
                          <div class="grid grid-cols-1 gap-4">
                            <!-- Square Integration Card -->
                            <IntegrationCard
                              name="Square"
                              description="Connect your Square account to sync payments, invoicing, and customer data"
                              icon="https://cdn.iconscout.com/icon/free/png-256/free-square-1865967-1581908.png"
                              :is-connected="isSquareConnected"
                              @click="showIntegration('square')"
                            />
                            
                            <!-- Analytics Pixels Card -->
                            <IntegrationCard
                              name="Analytics Pixels"
                              description="Connect Google Analytics, Google Tag Manager, and Facebook Pixel"
                              icon="https://cdn-icons-png.flaticon.com/512/2620/2620240.png"
                              :is-connected="hasAnalyticsConnected"
                              @click="showIntegration('analytics')"
                            />
                            
                            <!-- Placeholder for future integrations -->
                            <div class="border border-dashed border-gray-300 rounded-lg p-4 text-center text-gray-500">
                              <p class="text-sm">More integrations coming soon...</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <!-- Integration Detail View -->
                      <div v-else class="pb-6">
                        <div class="flex items-center px-4 py-3 border-b">
                          <button
                            type="button"
                            class="mr-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-yellow-500"
                            @click="activeIntegration = null"
                          >
                            <ArrowLeftIcon class="h-5 w-5" aria-hidden="true" />
                          </button>
                          <h3 class="text-lg font-medium">{{ getIntegrationTitle }}</h3>
                        </div>
                        
                        <div class="m-4 p-3 text-black rounded-md">
                          <SquareIntegration v-if="activeIntegration === 'square'" />
                          <AnalyticsPixels v-if="activeIntegration === 'analytics'" />
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import store from "../../../store";
import { XIcon, ArrowLeftIcon } from "@heroicons/vue/outline";
import AnalyticsPixels from "./AnalyticsPixels.vue";
import SquareIntegration from "./SquareIntegration.vue";
import IntegrationCard from "./IntegrationCard.vue";
import { projectFirestore } from "../../../firebase/config";
import { doc, getDoc } from "firebase/firestore";

const integrationOpenState = computed(() => {
  return store.state.integrationsOpen;
});

const close = () => {
  store.state.integrationsOpen = false;
};

const activeIntegration = ref(null);
const isSquareConnected = ref(false);
const hasAnalyticsConnected = ref(false);

const showIntegration = (integration) => {
  activeIntegration.value = integration;
};

const getIntegrationTitle = computed(() => {
  switch (activeIntegration.value) {
    case 'square':
      return 'Square Integration';
    case 'analytics':
      return 'Analytics Pixels';
    default:
      return 'Integration';
  }
});

// Fetch integration status on mount
onMounted(async () => {
  try {
    if (!store.state.currentUser) {
      console.error('No current user found');
      return;
    }
    
    const userDocRef = doc(projectFirestore, 'users', store.state.currentUser.uid);
    const configDocRef = doc(userDocRef, 'config', 'integrations');
    
    const docSnap = await getDoc(configDocRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      
      // Check Square connection
      isSquareConnected.value = !!(data.square && data.square.accessToken);
      
      // Check if any analytics are connected
      hasAnalyticsConnected.value = !!(
        (data.googleAnalytics4 && data.googleAnalytics4.length > 0) ||
        (data.googleTagManager && data.googleTagManager.length > 0) ||
        (data.facebookPixel && data.facebookPixel.length > 0)
      );
    }
  } catch (error) {
    console.error('Error fetching integration data:', error);
  }
});

let userConfig = computed(() => {
  return store.state.userConfig;
});
</script>
