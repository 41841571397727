import { ref } from "vue";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import store from "../store";
import { app } from "../firebase/config";

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);

const getUserDocument = () => {
  const userDocuments = ref(null);

  // Get the currently signed-in user's ID
  const userId = auth.currentUser?.uid;
  if (!userId) {
    console.error("No user is currently signed in.");
    return { userDocuments, checkAccountLock: () => false };
  }

  const collectionRef = doc(db, "users", userId);

  getDoc(collectionRef)
    .then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        userDocuments.value = data;
        store.state.userConfig = data;
        store.state.isSubscribed = data.subscription;
        store.state.isTrial = data.trial;
        store.state.accountLock = data.accountLock;
        store.state.customer = data.customer;
      } else {
        console.error("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  const checkAccountLock = async () => {
    return store.state.accountLock ? true : false;
  };

  return { userDocuments, checkAccountLock };
};

export default getUserDocument;
