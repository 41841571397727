import { ref } from "vue";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import store from "../store/index";
import getUser from "@/composables/getUser";

const { user } = getUser();
const projectStorage = getStorage();

const useStorage = () => {
  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);

  const uploadImage = async (file, vehicleType) => {
    filePath.value = `${store.state.companyProfileName}/gallery/${vehicleType}/${file.name}`;
    const storageReference = storageRef(projectStorage, filePath.value);

    try {
      const res = await uploadBytes(storageReference, file);
      url.value = await getDownloadURL(res.ref);
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const uploadWithDirectPath = async (file, exactPath) => {
    console.log(`[Storage] Company profile name:`, store.state.companyProfileName);
    console.log(`[Storage] Starting upload with exact path: ${exactPath}`);
    
    // Ensure the path is clean (remove any leading slashes)
    const cleanPath = exactPath.startsWith('/') ? exactPath.substring(1) : exactPath;
    
    // Use clean path directly without any other additions
    filePath.value = cleanPath;
    console.log(`[Storage] Final filepath used: ${filePath.value}`);
    
    const storageReference = storageRef(projectStorage, filePath.value);
    console.log(`[Storage] Storage reference created`, storageReference);
    
    try {
      console.log(`[Storage] Uploading file...`);
      const res = await uploadBytes(storageReference, file);
      console.log(`[Storage] Upload complete, getting download URL...`);
      url.value = await getDownloadURL(res.ref);
      console.log(`[Storage] Successfully uploaded to: ${url.value}`);
    } catch (err) {
      console.error(`[Storage] Error uploading to ${exactPath}:`, err);
      error.value = err.message;
    }
  };

  // Special function specifically for manufacturer images
  const uploadManufacturerImage = async (file, brandName) => {
    console.log(`[Storage] Uploading manufacturer image for brand: ${brandName}`);
    
    // Use the very specific S3 Assets path format without any company prefix
    const manufacturerPath = `S3 Assets/Manufacturers/${brandName}/${file.name}`;
    console.log(`[Storage] Manufacturer path: ${manufacturerPath}`);
    
    try {
      // Create a storage reference directly with the exact path
      const imageRef = storageRef(projectStorage, manufacturerPath);
      console.log(`[Storage] Manufacturer storage ref created:`, imageRef);
      
      // Upload the file
      console.log(`[Storage] Starting manufacturer image upload...`);
      const uploadResult = await uploadBytes(imageRef, file);
      console.log(`[Storage] Manufacturer upload complete:`, uploadResult);
      
      // Get the download URL
      url.value = await getDownloadURL(uploadResult.ref);
      filePath.value = manufacturerPath;
      
      console.log(`[Storage] Manufacturer image URL: ${url.value}`);
      console.log(`[Storage] Manufacturer image path: ${filePath.value}`);
      
      return {
        url: url.value,
        path: filePath.value
      };
    } catch (err) {
      console.error(`[Storage] Manufacturer image upload error:`, err);
      error.value = err.message;
      throw err;
    }
  };

  // Function to upload directly to root - attempting to bypass any auto-prefixing
  const uploadDirectToRoot = async (file, exactPath) => {
    console.log(`[Storage] Attempting direct root upload with path: ${exactPath}`);
    
    try {
      // Temporarily store the company profile name
      const originalCompanyName = store.state.companyProfileName;
      
      // Temporarily set the company profile name to empty to test if this affects paths
      store.state.companyProfileName = "";
      console.log(`[Storage] Temporarily cleared company profile name`);
      
      // Create a completely new Storage instance to avoid any cached configs
      const freshStorage = getStorage();
      console.log(`[Storage] Created fresh Storage instance`);
      
      // Create ref with the fresh storage instance and the exact path
      // Make sure path has no leading slash
      const cleanPath = exactPath.startsWith('/') ? exactPath.substring(1) : exactPath;
      console.log(`[Storage] Using clean path: ${cleanPath}`);
      
      const rootRef = storageRef(freshStorage, cleanPath);
      console.log(`[Storage] Created root reference:`, rootRef);
      
      // Attempt the upload directly
      console.log(`[Storage] Starting direct root upload...`);
      const uploadResult = await uploadBytes(rootRef, file);
      console.log(`[Storage] Direct root upload complete:`, uploadResult);
      
      // Get the URL and restore company name
      url.value = await getDownloadURL(uploadResult.ref);
      filePath.value = cleanPath;
      store.state.companyProfileName = originalCompanyName;
      
      console.log(`[Storage] Direct root upload URL: ${url.value}`);
      console.log(`[Storage] Direct root upload path: ${filePath.value}`);
      console.log(`[Storage] Restored company profile name: ${store.state.companyProfileName}`);
      
      return {
        url: url.value,
        path: filePath.value
      };
    } catch (err) {
      console.error(`[Storage] Direct root upload error:`, err);
      error.value = err.message;
      throw err;
    }
  };

  const uploadLogo = async (file, company) => {
    filePath.value = `${company}/assets/logo.png`;
    const storageReference = storageRef(projectStorage, filePath.value);

    try {
      const res = await uploadBytes(storageReference, file);
      url.value = await getDownloadURL(res.ref);
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const uploadHero = async (file) => {
    filePath.value = `${store.state.companyProfileName}/heroImg`;
    const storageReference = storageRef(projectStorage, filePath.value);

    try {
      const res = await uploadBytes(storageReference, file);
      url.value = await getDownloadURL(res.ref);
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const uploadTeamImage = async (file) => {
    filePath.value = `${store.state.companyProfileName}/team/${file.name}`;
    const storageReference = storageRef(projectStorage, filePath.value);

    try {
      const res = await uploadBytes(storageReference, file);
      return await getDownloadURL(res.ref);
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const updateSmartCardAvatar = async (file, cardID) => {
    filePath.value = `${store.state.companyProfileName}/smartCards/${cardID}/smartCardAvatar.png`;
    const storageReference = storageRef(projectStorage, filePath.value);

    try {
      const res = await uploadBytes(storageReference, file);
      url.value = await getDownloadURL(res.ref);
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const deleteImage = async (path) => {
    const storageReference = storageRef(projectStorage, path);

    try {
      await deleteObject(storageReference);
      console.log("Image deleted successfully");
    } catch (error) {
      console.log(error.message);
      error.value = error.message;
    }
  };

  return {
    url,
    filePath,
    error,
    uploadImage,
    uploadWithDirectPath,
    uploadManufacturerImage,
    uploadDirectToRoot,
    deleteImage,
    uploadHero,
    uploadLogo,
    updateSmartCardAvatar,
    uploadTeamImage,
  };
};

export default useStorage;
