<script setup>
import { onMounted, ref } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/outline";
import { projectFirestore } from "../../../firebase/config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import store from "../../../store";

// Add data properties for save and cancel buttons
const isSaving_googleAnalytics4 = ref(false);
const isSaving_googleTagManager = ref(false);
const isSaving_facebookPixel = ref(false);

const googleAnalytics4 = ref("");
const googleTagManager = ref("");
const facebookPixel = ref("");

// Get integration document reference
const getIntegrationRef = () => {
  const userDocRef = doc(projectFirestore, "users", store.state.currentUser.uid);
  return doc(userDocRef, "config", "integrations");
};

onMounted(async () => {
  try {
    const integrationRef = getIntegrationRef();
    const docSnap = await getDoc(integrationRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      googleAnalytics4.value = data.googleAnalytics4 || "";
      googleTagManager.value = data.googleTagManager || "";
      facebookPixel.value = data.facebookPixel || "";
    }
  } catch (error) {
    console.error("Error fetching analytics data:", error);
  }
});

// Save button click handler
const onSave = async (integrationName) => {
  const integrationRef = getIntegrationRef();
  
  // Perform save logic here
  switch (integrationName) {
    case "Google":
      isSaving_googleAnalytics4.value = true;
      try {
        await setDoc(integrationRef, {
          googleAnalytics4: googleAnalytics4.value,
          ga4Updated: new Date(),
        }, { merge: true });
      } catch (error) {
        console.error("Error saving Google Analytics:", error);
      } finally {
        isSaving_googleAnalytics4.value = false;
      }
      break;
      
    case "Google Tag Manager":
      isSaving_googleTagManager.value = true;
      try {
        await setDoc(integrationRef, {
          googleTagManager: googleTagManager.value,
          gtagUpdated: new Date(),
        }, { merge: true });
      } catch (error) {
        console.error("Error saving Google Tag Manager:", error);
      } finally {
        isSaving_googleTagManager.value = false;
      }
      break;
      
    case "Facebook Pixel":
      isSaving_facebookPixel.value = true;
      try {
        await setDoc(integrationRef, {
          facebookPixel: facebookPixel.value,
          facebookPixelUpdated: new Date(),
        }, { merge: true });
      } catch (error) {
        console.error("Error saving Facebook Pixel:", error);
      } finally {
        isSaving_facebookPixel.value = false;
      }
      break;
  }
};
</script>

<template>
  <div class="flex space-x-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
        fill="#4285F4"
      />
      <path
        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
        fill="#34A853"
      />
      <path
        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
        fill="#FBBC05"
      />
      <path
        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
        fill="#EA4335"
      />
      <path d="M1 1h22v22H1z" fill="none" />
    </svg>
  </div>

  <!-- GOOGLE ANALYTICS 4 -->
  <form @submit.prevent="onSave('Google')">
    <div class="relative mt-4 rounded-md shadow-sm">
      <p>Google Analytics 4</p>
      <div class="flex space-x-1 justify-center items-center">
        <input
          type="name"
          v-model="googleAnalytics4"
          class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6 font-semibold"
          :class="googleAnalytics4.length > 5 ? 'bg-green-100' : ''"
          placeholder="G-AMNNSC15MG"
        />

        <!-- Save button -->
        <button
          type="submit"
          :disabled="isSaving_googleAnalytics4"
          class="inline-flex items-center px-4 mr-2 py-2 text-sm font-medium text-white bg-gray-50 border border-transparent rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
        >
          <svg
            v-if="isSaving_googleAnalytics4"
            class="animate-spin -ml-1 h-5 w-5 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0120 12h4c0-6.627-5.373-12-12-12v4c3.042 0 5.824 1.135 7.938 3l-2.647 3zM9.171 4.757l-2.828 2.828 1.415 1.414 2.828-2.828-1.415-1.414zM14.828 19.243l2.828-2.828-1.414-1.414-2.828 2.828 1.414 1.414z"
            ></path>
          </svg>
          <span v-if="!isSaving_googleAnalytics4">
            <CheckCircleIcon
              class="h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          </span>
        </button>
      </div>
    </div>
  </form>

  <!-- GOOGLE TAG MANAGER -->
  <form @submit.prevent="onSave('Google Tag Manager')">
    <div class="relative mt-4 rounded-md shadow-sm">
      <p>Google Tag Manager</p>
      <div class="flex space-x-1 justify-center items-center">
        <input
          type="name"
          v-model="googleTagManager"
          class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6 font-semibold"
          :class="googleTagManager.length > 5 ? 'bg-green-100' : ''"
          placeholder="GTM-LEFDKGZQ"
        />

        <!-- Save button -->
        <button
          type="submit"
          :disabled="isSaving_googleTagManager"
          class="inline-flex items-center px-4 mr-2 py-2 text-sm font-medium text-white bg-gray-50 border border-transparent rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
        >
          <svg
            v-if="isSaving_googleTagManager"
            class="animate-spin -ml-1 h-5 w-5 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0120 12h4c0-6.627-5.373-12-12-12v4c3.042 0 5.824 1.135 7.938 3l-2.647 3zM9.171 4.757l-2.828 2.828 1.415 1.414 2.828-2.828-1.415-1.414zM14.828 19.243l2.828-2.828-1.414-1.414-2.828 2.828 1.414 1.414z"
            ></path>
          </svg>
          <span v-if="!isSaving_googleTagManager">
            <CheckCircleIcon
              class="h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          </span>
        </button>
      </div>
    </div>
  </form>

  <!-- SEPERATOR -->
  <div class="h-1 w-full bg-white my-8" />
  <!-- SEPERATOR -->

  <!-- FACEBOOK -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 48 48"
    version="1.1"
  >
    <g
      id="Icons"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Color-"
        transform="translate(-200.000000, -160.000000)"
        fill="#4460A0"
      >
        <path
          d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z"
          id="Facebook"
        ></path>
      </g>
    </g>
  </svg>

  <!-- Facebook Pixel -->
  <form @submit.prevent="onSave('Facebook Pixel')">
    <div class="relative mt-4 rounded-md shadow-sm">
      <p>Facebook Pixel</p>
      <div class="flex space-x-1 justify-center items-center">
        <input
          type="name"
          v-model="facebookPixel"
          class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6 font-semibold"
          :class="facebookPixel.length > 5 ? 'bg-green-100' : ''"
          placeholder="723652235132153"
        />

        <!-- Save button -->
        <button
          type="submit"
          :disabled="isSaving_facebookPixel"
          class="inline-flex items-center px-4 mr-2 py-2 text-sm font-medium text-white bg-gray-50 border border-transparent rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
        >
          <svg
            v-if="isSaving_facebookPixel"
            class="animate-spin -ml-1 h-5 w-5 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0120 12h4c0-6.627-5.373-12-12-12v4c3.042 0 5.824 1.135 7.938 3l-2.647 3zM9.171 4.757l-2.828 2.828 1.415 1.414 2.828-2.828-1.415-1.414zM14.828 19.243l2.828-2.828-1.414-1.414-2.828 2.828 1.414 1.414z"
            ></path>
          </svg>
          <span v-if="!isSaving_facebookPixel">
            <CheckCircleIcon
              class="h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          </span>
        </button>
      </div>
    </div>
  </form>
</template>
