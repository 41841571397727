import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Calls the squareAuthRequest Firebase function to authenticate with Square
 * @param {Object} data - The data to pass to the function
 * @param {string} data.code - The authorization code from Square
 * @param {string} data.state - The state parameter for CSRF protection
 * @param {string} data.environment - The environment (localhost or production)
 * @returns {Promise} - The result of the function call
 */
export const squareAuthRequest = async (data) => {
  const functions = getFunctions();
  const functionCall = httpsCallable(functions, 'squareAuthRequest');
  return functionCall(data);
};

/**
 * Calls the refreshSquareToken Firebase function to refresh the Square access token
 * @param {Object} data - The data to pass to the function
 * @param {string} data.userId - The user ID
 * @returns {Promise} - The result of the function call
 */
export const refreshSquareToken = async (data) => {
  const functions = getFunctions();
  const functionCall = httpsCallable(functions, 'refreshSquareToken');
  return functionCall(data);
}; 