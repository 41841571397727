<template>
  <div class="space-y-4">
    <div class="flex items-center space-x-3">
      <img src="https://cdn.iconscout.com/icon/free/png-256/free-square-1865967-1581908.png" alt="Square Logo" class="h-8 w-8" />
      <h3 class="text-lg font-medium">Square Integration</h3>
    </div>

    <div class="bg-gray-50 p-4 rounded-md">
      <p class="text-sm text-gray-600 mb-4">
        Connect your Square account to sync payments, invoices, and customer data with Tint Bolt.
      </p>
      
      <div class="space-y-4">
        <div class="flex items-center justify-between">
          <div>
            <h4 class="font-medium">Connection Status</h4>
            <p class="text-sm text-gray-500">
              {{ isConnected ? 'Connected to Square' : 'Not connected' }}
            </p>
          </div>
          <div>
            <span v-if="isConnected" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              Connected
            </span>
            <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              Disconnected
            </span>
          </div>
        </div>

        <div v-if="isConnected" class="space-y-2">
          <div class="flex justify-between items-center">
            <div>
              <h4 class="font-medium">Connected Account</h4>
              <p class="text-sm text-gray-500">{{ squareData.merchantId || 'Unknown' }}</p>
            </div>
            <button 
              @click="disconnectSquare" 
              class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Disconnect
            </button>
          </div>
          
          <div class="pt-2">
            <h4 class="font-medium mb-1">Permissions</h4>
            <ul class="text-sm text-gray-600 space-y-1 pl-5 list-disc">
              <li>Access to payment processing</li>
              <li>Customer management</li>
              <li>Inventory management</li>
              <li>Order management</li>
            </ul>
          </div>
          
          <div class="pt-2">
            <h4 class="font-medium mb-1">Connected Since</h4>
            <p class="text-sm text-gray-500">{{ squareData.createdAt || 'Unknown' }}</p>
          </div>
          
          <div class="pt-2">
            <h4 class="font-medium mb-1">Token Refresh Date</h4>
            <p class="text-sm text-gray-500">{{ squareData.refreshAt || 'Unknown' }}</p>
          </div>
        </div>

        <div class="pt-2">
          <button 
            v-if="!isConnected" 
            @click="connectSquare" 
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Connect to Square
          </button>
          <button 
            v-else 
            @click="refreshToken" 
            :disabled="isRefreshing"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg 
              v-if="isRefreshing" 
              class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24"
            >
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {{ isRefreshing ? 'Refreshing...' : 'Refresh Token' }}
          </button>
        </div>
        
        <!-- Troubleshooting section -->
        <div v-if="!isConnected" class="mt-6 pt-4 border-t border-gray-200">
          <h4 class="font-medium mb-2">Troubleshooting</h4>
          <p class="text-sm text-gray-600 mb-2">
            If you're having trouble connecting to Square, try these steps:
          </p>
          <ol class="text-sm text-gray-600 space-y-1 pl-5 list-decimal">
            <li>Verify your Square application is set up correctly in the <a href="https://developer.squareup.com/apps" target="_blank" class="text-blue-600 hover:underline">Square Developer Dashboard</a></li>
            <li>Make sure your redirect URI is set to: <code class="bg-gray-100 px-1 py-0.5 rounded">{{ redirectUri }}</code></li>
            <li>Check that you're using the correct application ID</li>
          </ol>
          
          <!-- Environment and Client ID Info -->
          <div class="mt-4 p-3 bg-gray-50 rounded-md">
            <h5 class="font-medium text-sm mb-2">Current Configuration</h5>
            <div class="text-xs text-gray-600 space-y-1">
              <p><span class="font-medium">Environment:</span> {{ currentEnvironment }}</p>
              <p><span class="font-medium">Client ID:</span> {{ currentEnvironment.includes('Sandbox') ? SANDBOX_CLIENT_ID : 'Production Client ID' }}</p>
              <p><span class="font-medium">Redirect URI:</span> {{ redirectUri }}</p>
            </div>
          </div>
          
          <div class="mt-4">
            <p class="text-sm font-medium text-gray-700 mb-1">Try direct OAuth URL:</p>
            <div class="space-y-2">
              <a 
                :href="getDirectOAuthUrl"
                target="_blank"
                class="text-sm text-blue-600 hover:underline block"
              >
                Connect with Square Sandbox (New Tab)
              </a>
              <button
                @click="directConnectSquare"
                class="mt-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Connect with Square Sandbox (Same Window)
              </button>
              <a 
                :href="getDirectSandboxLink" 
                target="_blank"
                class="text-sm text-blue-600 hover:underline block mt-2"
              >
                Open Square Sandbox Dashboard
              </a>
              <button
                @click="generateAndShowTestLinks"
                class="mt-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Generate Test Link with New State
              </button>
            </div>
          </div>
        </div>
        <div v-if="testLinks.connectSquareupsandbox" class="mt-2 p-3 bg-gray-50 rounded-md text-xs">
          <div class="mb-2">
            <p class="font-medium mb-1">Square Sandbox OAuth URL:</p>
            <a :href="testLinks.connectSquareupsandbox" target="_blank" class="text-blue-600 hover:underline break-all">
              {{ testLinks.connectSquareupsandbox }}
            </a>
          </div>
          <p class="mt-2 text-gray-500">
            State: {{ testLinks.state }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { projectFirestore } from '../../../firebase/config';
import { refreshSquareToken } from '../../../firebase/functions';
import store from '../../../store';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

// Hardcoded client IDs for development and testing
// In production, these should be set in your .env file
// IMPORTANT: Replace these with your actual Square application IDs
const SANDBOX_CLIENT_ID = 'sandbox-sq0idb-TfgCIrbAbPqE3HsTdK-LHA'; // Your actual sandbox client ID
const PRODUCTION_CLIENT_ID = 'sq0idp-mTB0hquKnLp2DEujTepgRA'; // Your actual production client ID

const isConnected = ref(false);
const isRefreshing = ref(false);
const squareData = ref({
  accessToken: '',
  refreshToken: '',
  expiresAt: null,
  createdAt: null,
  refreshAt: null,
  merchantId: ''
});
const testLinks = ref({
  connectSquareupsandbox: '',
  sandboxSquareup: '',
  state: ''
});
const origin = ref('');

// Direct link to Square sandbox dashboard
const getDirectSandboxLink = computed(() => {
  return 'https://developer.squareupsandbox.com/apps';
});

// Get the current environment
const currentEnvironment = computed(() => {
  return origin.value.includes('localhost') ? 'Sandbox (localhost)' : 'Production';
});

// Get the redirect URI
const redirectUri = computed(() => {
  return origin.value ? `${origin.value}/square-callback` : '';
});

// Initialize window-dependent values
onMounted(() => {
  if (typeof window !== 'undefined') {
    origin.value = window.location.origin;
  }
});

// Connect to Square
const connectSquare = async () => {
  // Generate a random state value for security
  const state = Math.random().toString(36).substring(2, 15);
  
  // Store the state in localStorage to verify when the user returns
  localStorage.setItem('squareOAuthState', state);
  
  // Determine environment
  const environment = origin.value.includes('localhost') ? 'localhost' : 'production';
  
  // Save the CSRF token to the user's document
  try {
    const userDocRef = doc(projectFirestore, 'users', store.state.currentUser.uid);
    await setDoc(userDocRef, { 
      csrf: state,
      squareEnvironment: environment 
    }, { merge: true });
  } catch (error) {
    console.error('Error saving CSRF token:', error);
    return;
  }
  
  // Get client ID - in Vue, environment variables must be prefixed with VUE_APP_ and accessed directly
  let clientId;
  
  // Determine which client ID to use based on environment
  if (environment === 'localhost') {
    clientId = SANDBOX_CLIENT_ID;
    console.log('Using Sandbox Client ID:', clientId);
  } else {
    clientId = PRODUCTION_CLIENT_ID;
    console.log('Using Production Client ID:', clientId);
  }
  
  // Construct the redirect URI
  const encodedRedirectUri = encodeURIComponent(redirectUri.value);
  
  // Construct the OAuth URL
  let oauthUrl;
  if (environment === 'localhost') {
    oauthUrl = `https://connect.squareupsandbox.com/oauth2/authorize?client_id=${clientId}&scope=MERCHANT_PROFILE_READ%20PAYMENTS_READ%20PAYMENTS_WRITE%20ORDERS_READ%20ORDERS_WRITE%20CUSTOMERS_READ%20CUSTOMERS_WRITE%20INVENTORY_READ%20INVENTORY_WRITE&response_type=code&state=${state}&redirect_uri=${encodedRedirectUri}`;
  } else {
    oauthUrl = `https://connect.squareup.com/oauth2/authorize?client_id=${clientId}&scope=MERCHANT_PROFILE_READ%20PAYMENTS_READ%20PAYMENTS_WRITE%20ORDERS_READ%20ORDERS_WRITE%20CUSTOMERS_READ%20CUSTOMERS_WRITE%20INVENTORY_READ%20INVENTORY_WRITE&response_type=code&state=${state}&redirect_uri=${encodedRedirectUri}`;
  }
  
  // Log the complete URL for troubleshooting
  console.log('Square OAuth URL:', oauthUrl);
  console.log('Redirect URI (decoded):', redirectUri.value);
  console.log('URL being used for redirect:', oauthUrl);
  
  // Redirect to Square's OAuth page
  window.location.href = oauthUrl;
};

// Disconnect from Square
const disconnectSquare = async () => {
  try {
    // Clear local data
    const userDocRef = doc(projectFirestore, 'users', store.state.currentUser.uid);
    await updateDoc(userDocRef, {
      square: null,
      merchant: null,
      tokenSecure: false
    });
    
    isConnected.value = false;
    squareData.value = {
      accessToken: '',
      refreshToken: '',
      expiresAt: null,
      createdAt: null,
      refreshAt: null,
      merchantId: ''
    };
    
  } catch (error) {
    console.error('Error disconnecting Square:', error);
  }
};

// Refresh Square token
const refreshToken = async () => {
  if (isRefreshing.value) return;
  
  isRefreshing.value = true;
  try {
    // Call Firebase function to refresh token
    const result = await refreshSquareToken({
      userId: store.state.currentUser.uid
    });
    
    if (result.data.status === 'Success') {
      // Refresh the data
      await fetchSquareData();
    }
  } catch (error) {
    console.error('Error refreshing Square token:', error);
  } finally {
    isRefreshing.value = false;
  }
};

// Fetch Square integration data
const fetchSquareData = async () => {
  try {
    const userDocRef = doc(projectFirestore, 'users', store.state.currentUser.uid);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      
      if (userData.square && userData.tokenSecure) {
        squareData.value = userData.square;
        squareData.value.merchantId = userData.merchant || 'Unknown';
        isConnected.value = true;
      } else {
        isConnected.value = false;
      }
    }
  } catch (error) {
    console.error('Error fetching Square integration data:', error);
  }
};

// Fetch Square integration data on mount
onMounted(async () => {
  await fetchSquareData();
});

// Generate test links for troubleshooting
const generateTestLinks = () => {
  // Generate a random state value for security
  const state = Math.random().toString(36).substring(2, 15);
  
  // Store the state in localStorage to verify when the user returns
  localStorage.setItem('squareOAuthState', state);
  
  // Construct the redirect URI
  const encodedRedirectUri = encodeURIComponent(redirectUri.value);
  
  // Create the OAuth URL
  const oauthUrl = `https://connect.squareupsandbox.com/oauth2/authorize?client_id=${SANDBOX_CLIENT_ID}&scope=MERCHANT_PROFILE_READ%20PAYMENTS_READ%20PAYMENTS_WRITE%20ORDERS_READ%20ORDERS_WRITE%20CUSTOMERS_READ%20CUSTOMERS_WRITE%20INVENTORY_READ%20INVENTORY_WRITE&response_type=code&state=${state}&redirect_uri=${encodedRedirectUri}`;
  
  // Log the complete URL for troubleshooting
  console.log('Test Link Generated:');
  console.log('Square Sandbox OAuth URL:', oauthUrl);
  console.log('Redirect URI (decoded):', redirectUri.value);
  
  return {
    connectSquareupsandbox: oauthUrl,
    state
  };
};

// Generate and show test links
const generateAndShowTestLinks = () => {
  const links = generateTestLinks();
  testLinks.value = links;
  console.log('Test Links Generated:', links);
};

// Get the direct sandbox OAuth URL with proper state handling
const getDirectOAuthUrl = computed(() => {
  const state = Math.random().toString(36).substring(2, 15);
  // Store the state in localStorage
  if (typeof window !== 'undefined') {
    localStorage.setItem('squareOAuthState', state);
  }
  return `https://connect.squareupsandbox.com/oauth2/authorize?client_id=${SANDBOX_CLIENT_ID}&scope=MERCHANT_PROFILE_READ%20PAYMENTS_READ%20PAYMENTS_WRITE%20ORDERS_READ%20ORDERS_WRITE%20CUSTOMERS_READ%20CUSTOMERS_WRITE%20INVENTORY_READ%20INVENTORY_WRITE&response_type=code&state=${state}&redirect_uri=${encodeURIComponent(redirectUri.value)}`;
});

// Direct connect to Square (simplified version)
const directConnectSquare = () => {
  try {
    // Generate a random state value for security
    const state = Math.random().toString(36).substring(2, 15);
    
    // Store the state in localStorage to verify when the user returns
    localStorage.setItem('squareOAuthState', state);
    
    // Construct the redirect URI
    const encodedRedirectUri = encodeURIComponent(redirectUri.value);
    
    // Create the OAuth URL - explicitly using connect.squareupsandbox.com
    const oauthUrl = `https://connect.squareupsandbox.com/oauth2/authorize?client_id=${SANDBOX_CLIENT_ID}&scope=MERCHANT_PROFILE_READ%20PAYMENTS_READ%20PAYMENTS_WRITE%20ORDERS_READ%20ORDERS_WRITE%20CUSTOMERS_READ%20CUSTOMERS_WRITE%20INVENTORY_READ%20INVENTORY_WRITE&response_type=code&state=${state}&redirect_uri=${encodedRedirectUri}`;
    
    // Log the URL for debugging
    console.log('Direct connect URL:', oauthUrl);
    
    // Redirect to Square's OAuth page
    window.location.href = oauthUrl;
  } catch (error) {
    console.error('Error in directConnectSquare:', error);
  }
};
</script> 