<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 z-50 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        @click="close"
      ></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <!-- Permission denied message for non-admin/non-moderator users -->
      <div
        v-if="!isAuthorized"
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Permission Denied
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  You don't have permission to manage vehicles. This feature is available only to administrators and moderators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            @click="close"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>

      <!-- Actual vehicle manager content - only shown to authorized users -->
      <div
        v-else
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Vehicle Manager
              </h3>
              <div class="flex items-center justify-between mt-2 mb-3">
                <button 
                  @click="quickBackup" 
                  class="text-sm px-2 py-1 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
                >
                  Backup Vehicles Now
                </button>
                <button 
                  @click="manualBackup" 
                  class="text-sm px-2 py-1 bg-green-500 text-white rounded shadow hover:bg-green-600"
                >
                  Manual Backup
                </button>
              </div>
              <div class="mt-4">
                <div class="mb-4">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    Select Manufacturer
                  </label>
                  <select
                    v-model="selectedBrand"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
                  >
                    <option value="" disabled>Select a manufacturer</option>
                    <option v-for="(brand, index) in vehicleBrands" :key="index" :value="index">
                      {{ brand.name }}
                    </option>
                    <option value="new">+ Add New Manufacturer</option>
                  </select>
                </div>

                <div v-if="selectedBrand === 'new'" class="mb-4">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    New Manufacturer Name
                  </label>
                  <input
                    type="text"
                    v-model="newBrandName"
                    class="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter manufacturer name"
                  />
                  <button
                    @click="addNewBrand"
                    class="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  >
                    Add Manufacturer
                  </button>
                </div>

                <div v-if="selectedBrand !== '' && selectedBrand !== 'new'" class="mb-4">
                  <div class="flex justify-between items-center">
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      Add New Model
                    </label>
                  </div>
                  <div class="flex gap-2 mb-2">
                    <input
                      type="text"
                      v-model="newModelName"
                      class="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="Enter model name"
                    />
                    <button
                      @click="addNewModel"
                      class="mt-1 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div v-if="selectedBrand !== '' && selectedBrand !== 'new'">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    Models for {{ getBrandName(selectedBrand) }}
                  </label>
                  <div class="max-h-60 overflow-y-auto border rounded-md p-2">
                    <div
                      v-for="(model, index) in getModels"
                      :key="index"
                      class="mb-2 p-2 border rounded flex justify-between items-center"
                    >
                      <div class="flex items-center gap-2">
                        <!-- Show model image if available - check multiple possible property names -->
                        <img 
                          v-if="model.image || model.imageUrl || model.img" 
                          :src="model.image || model.imageUrl || model.img" 
                          alt="Model" 
                          class="w-12 h-12 rounded-md object-cover"
                        />
                        <span class="font-medium">{{ model.name }}</span>
                      </div>
                      <div class="flex gap-2">
                        <button
                          @click="editModel(index)"
                          class="text-blue-600 hover:text-blue-800"
                        >
                          Edit
                        </button>
                        <button
                          @click="deleteModel(index)"
                          class="text-red-600 hover:text-red-800"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div v-if="getModels.length === 0" class="text-center text-gray-500 py-2">
                      No models available
                    </div>
                  </div>
                </div>

                <div v-if="editingModel !== null" class="mt-4 p-3 border rounded-md bg-gray-50">
                  <div class="flex justify-between items-center mb-2">
                    <h4 class="font-medium">Edit Model: {{ getModels[editingModel].name }}</h4>
                    <button @click="editingModel = null" class="text-gray-600 hover:text-gray-800">
                      Cancel
                    </button>
                  </div>
                  
                  <div class="mb-3">
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      Model Name
                    </label>
                    <input
                      type="text"
                      v-model="editModelName"
                      class="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      Add Body Type
                    </label>
                    <div class="flex gap-2">
                      <input
                        type="text"
                        v-model="newBodyType"
                        class="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter body type (e.g., Sedan, SUV)"
                      />
                      <button
                        @click="addBodyType"
                        class="mt-1 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                      >
                        Add
                      </button>
                    </div>
                    
                    <!-- Add body type image upload section -->
                    <div class="mt-2 border rounded-md p-3 bg-gray-100">
                      <label class="block text-sm font-medium text-gray-700 mb-2">
                        Upload Body Type Image (optional)
                      </label>
                      <div class="flex items-center gap-2">
                        <input
                          type="file"
                          id="body-type-image-input"
                          @change="handleBodyTypeImageChange"
                          accept="image/*"
                          class="text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-yellow-50 file:text-yellow-700 hover:file:bg-yellow-100"
                        />
                        <button
                          @click="uploadBodyTypeImage"
                          :disabled="!bodyTypeImageFile || isUploading"
                          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          <span v-if="isUploading">Uploading...</span>
                          <span v-else>Upload</span>
                        </button>
                      </div>
                      <div v-if="bodyTypeImageUrl" class="mt-2">
                        <p class="text-xs text-green-600">Image uploaded successfully!</p>
                        <img :src="bodyTypeImageUrl" alt="Body type preview" class="mt-1 max-h-24 rounded-md border" />
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      Body Types
                    </label>
                    <div class="max-h-40 overflow-y-auto border rounded-md p-2">
                      <div
                        v-for="(segment, index) in getSegments"
                        :key="index"
                        class="mb-1 p-1 border rounded flex justify-between items-center"
                      >
                        <div class="flex items-center gap-2">
                          <!-- Show body type image if available - check multiple possible property names -->
                          <img 
                            v-if="segment?.option1?.image || segment?.option1?.imageUrl || segment?.option1?.img" 
                            :src="segment.option1.image || segment.option1.imageUrl || segment.option1.img" 
                            alt="Body type"
                            class="w-10 h-10 rounded-md object-cover"
                          />
                          <span>{{ getSegmentName(segment) }}</span>
                        </div>
                        <button
                          @click="deleteBodyType(index)"
                          class="text-red-600 hover:text-red-800 text-sm"
                        >
                          Remove
                        </button>
                      </div>
                      <div v-if="getSegments.length === 0" class="text-center text-gray-500 py-2">
                        No body types available
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-end mt-3">
                    <button
                      @click="saveModelChanges"
                      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            @click="close"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { doc, getDoc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import { projectAuth } from '../../firebase/config';
import useStorage from '@/composables/useStorage'; // Import storage composable

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
});

console.log("[VehicleManager] Component initialized, isOpen:", props.isOpen);

const emit = defineEmits(['close', 'updated']);
const store = useStore();

// Check if user is authorized (admin or moderator)
const isAuthorized = computed(() => {
  const user = store.state.userConfig;
  console.log("[VehicleManager] Checking authorization for user:", user);
  
  // Check if user has admin or moderator role
  // Adjust these properties based on your actual user object structure
  const isAdmin = user?.role === 'admin' || user?.isAdmin === true;
  const isModerator = user?.role === 'moderator' || user?.isModerator === true;
  
  const hasPermission = isAdmin || isModerator;
  console.log("[VehicleManager] User authorization status:", hasPermission);
  return hasPermission;
});

// State variables
const vehicles = ref([]);
const selectedBrand = ref('');
const newBrandName = ref('');
const newModelName = ref('');
const editingModel = ref(null);
const editModelName = ref('');
const newBodyType = ref('');

// Initialize the storage composable - now with all the upload functions
const { uploadImage, uploadWithDirectPath, uploadManufacturerImage, uploadDirectToRoot, url, filePath, error: uploadError } = useStorage();

// Add new state variables for images
const modelImageFile = ref(null);  // Keeping this for compatibility, but we won't use it
const bodyTypeImageFile = ref(null);
const modelImageUrl = ref(''); // Keeping this for compatibility, but we won't use it
const bodyTypeImageUrl = ref('');
const isUploading = ref(false);

// Load vehicles data
const loadVehicles = async () => {
  console.log("[VehicleManager] Loading vehicles data");
  
  // Only load vehicles if user is authorized
  if (!isAuthorized.value) {
    console.log("[VehicleManager] Unauthorized access attempt - not loading vehicles data");
    return;
  }
  
  try {
    const vehiclesDocRef = doc(projectFirestore, 'assets', 'vehicles');
    const vehiclesDocSnap = await getDoc(vehiclesDocRef);
    if (vehiclesDocSnap.exists()) {
      // Extract vehicles data
      const data = vehiclesDocSnap.data();
      console.log("[VehicleManager] Complete document data:", data);
      
      // Check if the data follows the expected structure: vehicles.brands
      if (data.vehicles && data.vehicles.brands && Array.isArray(data.vehicles.brands)) {
        // This is the correct structure based on the user's sample
        vehicles.value = data.vehicles.brands;
        console.log("[VehicleManager] Successfully loaded vehicles from vehicles.brands:", vehicles.value.length);
      } else if (data.brands && Array.isArray(data.brands)) {
        // Alternative structure - brands at top level
        vehicles.value = data.brands;
        console.log("[VehicleManager] Loaded vehicles from top-level brands:", vehicles.value.length);
      } else {
        console.log("[VehicleManager] Could not find brands array in expected locations. Data structure:", data);
        // Create a default structure if none exists
        vehicles.value = [];
        
        // Create the right structure in Firestore
        await updateDoc(vehiclesDocRef, {
          'vehicles.brands': []
        });
        console.log("[VehicleManager] Created empty vehicles.brands array in Firestore");
      }
    } else {
      console.log("[VehicleManager] No vehicles document found - creating one");
      // Document doesn't exist - create it with the correct structure
      await setDoc(vehiclesDocRef, {
        vehicles: {
          brands: []
        }
      });
      vehicles.value = [];
    }
  } catch (error) {
    console.error('[VehicleManager] Error loading vehicles:', error);
    vehicles.value = [];
  }
};

// Call loadVehicles when the component is mounted
onMounted(() => {
  console.log("[VehicleManager] Component mounted");
  loadVehicles();
});

// Computed properties
const vehicleBrands = computed(() => {
  return vehicles.value;
});

const getModels = computed(() => {
  if (selectedBrand.value === '' || selectedBrand.value === 'new') return [];
  return vehicles.value[selectedBrand.value]?.models || [];
});

const getSegments = computed(() => {
  if (editingModel.value === null) return [];
  return vehicles.value[selectedBrand.value]?.models[editingModel.value]?.segments || [];
});

// Methods
const getBrandName = (index) => {
  return vehicles.value[index]?.name || '';
};

const getSegmentName = (segment) => {
  if (segment && segment.option1 && segment.option1.segment) {
    return segment.option1.segment;
  } else if (typeof segment === 'object') {
    for (const key in segment) {
      if (segment[key] && segment[key].segment) {
        return segment[key].segment;
      }
    }
  }
  return 'Unknown';
};

const close = () => {
  console.log("[VehicleManager] Closing modal");
  emit('close');
  resetForm();
};

const resetForm = () => {
  selectedBrand.value = '';
  newBrandName.value = '';
  newModelName.value = '';
  editingModel.value = null;
  editModelName.value = '';
  newBodyType.value = '';
};

const addNewBrand = async () => {
  const brandName = newBrandName.value.trim();
  if (!brandName) {
    console.error('[VehicleManager] Brand name is empty');
    return;
  }
  
  console.log(`[VehicleManager] Adding new brand: "${brandName}"`);
  
  // Declare previousVehicles outside the try block so it's available in the catch block
  let previousVehicles = null;
  
  try {
    // Check for duplicate brand name
    const duplicateBrand = vehicles.value.find(brand => 
      brand.name.toLowerCase() === brandName.toLowerCase()
    );
    
    if (duplicateBrand) {
      alert(`A brand with the name "${brandName}" already exists.`);
      console.warn(`[VehicleManager] Attempted to add duplicate brand: "${brandName}"`);
      return;
    }
    
    const newBrand = {
      name: brandName,
      models: []
    };
    
    // Create a deep copy of the vehicles array and add the new brand
    const updatedVehicles = JSON.parse(JSON.stringify(vehicles.value));
    updatedVehicles.push(newBrand);
    
    // Create a backup reference for potential rollback
    previousVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Update local state first for UI responsiveness
    vehicles.value = updatedVehicles;
    selectedBrand.value = (updatedVehicles.length - 1).toString();
    newBrandName.value = '';
    
    // Update Firestore with the correct path: 'vehicles.brands'
    console.log('[VehicleManager] Updating Firestore with the modified vehicles data');
    await updateDoc(doc(projectFirestore, 'assets', 'vehicles'), {
      'vehicles.brands': updatedVehicles
    });
    
    console.log(`[VehicleManager] Successfully added brand: "${brandName}"`);
    
    // Notify parent component
    emit('updated');
  } catch (error) {
    console.error('[VehicleManager] Error adding brand:', error);
    
    // Attempt to restore previous state if local update was performed but Firestore update failed
    if (previousVehicles) {
      console.log('[VehicleManager] Restoring previous vehicles state after error');
      vehicles.value = previousVehicles;
      selectedBrand.value = '';
    }
    
    // Show error to user
    alert(`Error adding brand: ${error.message || 'Unknown error'}`);
  }
};

const addNewModel = async () => {
  const modelName = newModelName.value.trim();
  if (!modelName || selectedBrand.value === '' || selectedBrand.value === 'new') {
    console.error('[VehicleManager] Model name is empty or no brand selected');
    return;
  }
  
  console.log(`[VehicleManager] Adding new model: "${modelName}"`);
  
  // Declare previousVehicles outside the try block
  let previousVehicles = null;
  
  try {
    const brandIndex = parseInt(selectedBrand.value);
    
    // Validate brand index
    if (isNaN(brandIndex) || brandIndex < 0 || brandIndex >= vehicles.value.length) {
      console.error(`[VehicleManager] Invalid brand index: ${brandIndex}`);
      return;
    }
    
    // Check for duplicate model name
    const duplicateModel = vehicles.value[brandIndex].models?.find(
      model => model.name.toLowerCase() === modelName.toLowerCase()
    );
    
    if (duplicateModel) {
      alert(`A model with the name "${modelName}" already exists for this brand.`);
      console.warn(`[VehicleManager] Attempted to add duplicate model: "${modelName}"`);
      return;
    }
    
    const newModel = {
      name: modelName,
      segments: []
      // Removed image property since we're not using it anymore
    };
    
    // Create a deep copy of the vehicles array
    const updatedVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Add the new model to the selected brand
    if (!updatedVehicles[brandIndex].models) {
      updatedVehicles[brandIndex].models = [];
    }
    updatedVehicles[brandIndex].models.push(newModel);
    
    // Create a backup reference for potential rollback
    previousVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Update local state first for UI responsiveness
    vehicles.value = updatedVehicles;
    newModelName.value = '';
    
    // Update Firestore with the correct path: 'vehicles.brands'
    console.log('[VehicleManager] Updating Firestore with the modified vehicles data');
    await updateDoc(doc(projectFirestore, 'assets', 'vehicles'), {
      'vehicles.brands': updatedVehicles
    });
    
    console.log(`[VehicleManager] Successfully added model: "${modelName}"`);
    
    // Notify parent component
    emit('updated');
  } catch (error) {
    console.error('[VehicleManager] Error adding model:', error);
    
    // Attempt to restore previous state if local update was performed but Firestore update failed
    if (previousVehicles) {
      console.log('[VehicleManager] Restoring previous vehicles state after error');
      vehicles.value = previousVehicles;
    }
    
    // Show error to user
    alert(`Error adding model: ${error.message || 'Unknown error'}`);
  }
};

const getExistingImageUrl = (obj) => {
  if (!obj) return '';
  
  // Check for all possible image property names
  return obj.image || obj.imageUrl || obj.img || '';
};

const editModel = (modelIndex) => {
  editingModel.value = modelIndex;
  const model = vehicles.value[selectedBrand.value].models[modelIndex];
  editModelName.value = model.name;
  
  // Keep this for compatibility, but we won't display it in the UI
  const existingImageUrl = getExistingImageUrl(model);
  if (existingImageUrl) {
    console.log(`[VehicleManager] Model has existing image: ${existingImageUrl}`);
    modelImageUrl.value = existingImageUrl;
  }
};

const deleteModel = async (modelIndex) => {
  if (!confirm('Are you sure you want to delete this model?')) return;
  
  // Declare previousVehicles outside the try block so it's available in the catch block
  let previousVehicles = null;
  
  try {
    const brandIndex = parseInt(selectedBrand.value);
    console.log(`[VehicleManager] Attempting to delete model at index ${modelIndex} from brand at index ${brandIndex}`);
    
    // Validate indices before proceeding
    if (isNaN(brandIndex) || brandIndex < 0 || brandIndex >= vehicles.value.length) {
      console.error(`[VehicleManager] Invalid brand index: ${brandIndex}`);
      return;
    }
    
    if (modelIndex < 0 || modelIndex >= vehicles.value[brandIndex].models.length) {
      console.error(`[VehicleManager] Invalid model index: ${modelIndex}`);
      return;
    }
    
    // Log the model being deleted for reference
    const modelToDelete = vehicles.value[brandIndex].models[modelIndex];
    console.log(`[VehicleManager] Deleting model: ${modelToDelete.name}`);
    
    // Create a copy of the vehicles array
    const updatedVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Remove the model from the selected brand
    updatedVehicles[brandIndex].models.splice(modelIndex, 1);
    
    // Create a backup reference for potential rollback
    previousVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Update local state first for UI responsiveness
    vehicles.value = updatedVehicles;
    
    // Reset editing state if we're deleting the model we're currently editing
    if (editingModel.value === modelIndex) {
      editingModel.value = null;
    } else if (editingModel.value > modelIndex) {
      // Adjust editingModel index if we're deleting a model with a lower index
      editingModel.value--;
    }
    
    // Update Firestore with the correct path: 'vehicles.brands'
    console.log('[VehicleManager] Updating Firestore with the modified vehicles data');
    await updateDoc(doc(projectFirestore, 'assets', 'vehicles'), {
      'vehicles.brands': updatedVehicles
    });
    
    console.log(`[VehicleManager] Successfully deleted model: ${modelToDelete.name}`);
    
    // Notify parent component
    emit('updated');
  } catch (error) {
    console.error('[VehicleManager] Error deleting model:', error);
    
    // Attempt to restore previous state if local update was performed but Firestore update failed
    if (previousVehicles) {
      console.log('[VehicleManager] Restoring previous vehicles state after error');
      vehicles.value = previousVehicles;
    }
    
    // Show error to user
    alert(`Error deleting model: ${error.message || 'Unknown error'}`);
  }
};

const addBodyType = async () => {
  const bodyType = newBodyType.value.trim();
  if (!bodyType || editingModel.value === null) {
    console.error('[VehicleManager] Body type is empty or no model is being edited');
    return;
  }
  
  console.log(`[VehicleManager] Adding new body type: "${bodyType}"`);
  
  // Declare previousVehicles outside the try block
  let previousVehicles = null;
  
  try {
    const brandIndex = parseInt(selectedBrand.value);
    const modelIndex = editingModel.value;
    
    // Validate indices
    if (isNaN(brandIndex) || brandIndex < 0 || brandIndex >= vehicles.value.length) {
      console.error(`[VehicleManager] Invalid brand index: ${brandIndex}`);
      return;
    }
    
    if (modelIndex < 0 || modelIndex >= vehicles.value[brandIndex].models.length) {
      console.error(`[VehicleManager] Invalid model index: ${modelIndex}`);
      return;
    }
    
    // Check for duplicate body type
    const segments = vehicles.value[brandIndex].models[modelIndex].segments || [];
    const duplicateSegment = segments.find(segment => {
      const segmentName = getSegmentName(segment);
      return segmentName.toLowerCase() === bodyType.toLowerCase();
    });
    
    if (duplicateSegment) {
      alert(`A body type with the name "${bodyType}" already exists for this model.`);
      console.warn(`[VehicleManager] Attempted to add duplicate body type: "${bodyType}"`);
      return;
    }
    
    // Log if we have an image URL
    if (bodyTypeImageUrl.value) {
      console.log(`[VehicleManager] Using image URL for body type: ${bodyTypeImageUrl.value}`);
    }
    
    // Create a new segment with the proper structure, including image URL if available
    const newSegment = {
      option1: {
        segment: bodyType,
        vehicleType: "Medium SUV", // Default vehicle type
        image: bodyTypeImageUrl.value || '' // Use the 'image' property consistently
      }
    };
    
    // Create a deep copy of the vehicles array
    const updatedVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Create a backup reference for potential rollback
    previousVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Add the new segment to the selected model
    if (!updatedVehicles[brandIndex].models[modelIndex].segments) {
      updatedVehicles[brandIndex].models[modelIndex].segments = [];
    }
    updatedVehicles[brandIndex].models[modelIndex].segments.push(newSegment);
    
    // Update local state first for UI responsiveness
    vehicles.value = updatedVehicles;
    
    // Update Firestore with the correct path: 'vehicles.brands'
    console.log('[VehicleManager] Updating Firestore with the modified vehicles data');
    await updateDoc(doc(projectFirestore, 'assets', 'vehicles'), {
      'vehicles.brands': updatedVehicles
    });
    
    console.log(`[VehicleManager] Successfully added body type: "${bodyType}"`);
    
    // Clear the form fields after successful addition
    newBodyType.value = '';
    bodyTypeImageUrl.value = ''; // Reset the image URL
    
    // Notify parent component
    emit('updated');
    
    // Show success message
    alert(`Body type "${bodyType}" successfully added!`);
  } catch (error) {
    console.error('[VehicleManager] Error adding body type:', error);
    
    // Attempt to restore previous state if local update was performed but Firestore update failed
    if (previousVehicles) {
      console.log('[VehicleManager] Restoring previous vehicles state after error');
      vehicles.value = previousVehicles;
    }
    
    // Show error to user
    alert(`Error adding body type: ${error.message || 'Unknown error'}`);
  }
};

const deleteBodyType = async (segmentIndex) => {
  if (!confirm('Are you sure you want to delete this body type?')) return;
  
  // Declare previousVehicles outside the try block so it's available in the catch block
  let previousVehicles = null;
  
  try {
    const brandIndex = parseInt(selectedBrand.value);
    const modelIndex = editingModel.value;
    
    console.log(`[VehicleManager] Attempting to delete body type at index ${segmentIndex} from model ${modelIndex} of brand ${brandIndex}`);
    
    // Validate indices before proceeding
    if (isNaN(brandIndex) || brandIndex < 0 || brandIndex >= vehicles.value.length) {
      console.error(`[VehicleManager] Invalid brand index: ${brandIndex}`);
      return;
    }
    
    if (modelIndex === null || modelIndex < 0 || modelIndex >= vehicles.value[brandIndex].models.length) {
      console.error(`[VehicleManager] Invalid model index: ${modelIndex}`);
      return;
    }
    
    if (segmentIndex < 0 || segmentIndex >= vehicles.value[brandIndex].models[modelIndex].segments.length) {
      console.error(`[VehicleManager] Invalid segment index: ${segmentIndex}`);
      return;
    }
    
    // Log the segment being deleted
    const segmentToDelete = vehicles.value[brandIndex].models[modelIndex].segments[segmentIndex];
    const segmentName = getSegmentName(segmentToDelete);
    console.log(`[VehicleManager] Deleting body type: ${segmentName}`);
    
    // Create a deep copy of the vehicles array
    const updatedVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Create a backup reference for potential rollback
    previousVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Remove the segment from the selected model
    updatedVehicles[brandIndex].models[modelIndex].segments.splice(segmentIndex, 1);
    
    // Update local state first for UI responsiveness
    vehicles.value = updatedVehicles;
    
    // Update Firestore with the correct path: 'vehicles.brands'
    console.log('[VehicleManager] Updating Firestore with the modified vehicles data');
    await updateDoc(doc(projectFirestore, 'assets', 'vehicles'), {
      'vehicles.brands': updatedVehicles
    });
    
    console.log(`[VehicleManager] Successfully deleted body type: ${segmentName}`);
    
    // Notify parent component
    emit('updated');
  } catch (error) {
    console.error('[VehicleManager] Error deleting body type:', error);
    
    // Attempt to restore previous state if local update was performed but Firestore update failed
    if (previousVehicles) {
      console.log('[VehicleManager] Restoring previous vehicles state after error');
      vehicles.value = previousVehicles;
    }
    
    // Show error to user
    alert(`Error deleting body type: ${error.message || 'Unknown error'}`);
  }
};

const saveModelChanges = async () => {
  const modelName = editModelName.value.trim();
  if (!modelName || editingModel.value === null) {
    console.error('[VehicleManager] Model name is empty or no model is being edited');
    return;
  }
  
  // Declare previousVehicles outside the try block
  let previousVehicles = null;
  
  try {
    const brandIndex = parseInt(selectedBrand.value);
    const modelIndex = editingModel.value;
    
    // Validate indices
    if (isNaN(brandIndex) || brandIndex < 0 || brandIndex >= vehicles.value.length) {
      console.error(`[VehicleManager] Invalid brand index: ${brandIndex}`);
      return;
    }
    
    if (modelIndex < 0 || modelIndex >= vehicles.value[brandIndex].models.length) {
      console.error(`[VehicleManager] Invalid model index: ${modelIndex}`);
      return;
    }
    
    console.log(`[VehicleManager] Saving changes to model: "${modelName}"`);
    
    // Create a deep copy of the vehicles array
    const updatedVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Create a backup reference for potential rollback
    previousVehicles = JSON.parse(JSON.stringify(vehicles.value));
    
    // Keep the existing model details
    const existingModel = updatedVehicles[brandIndex].models[modelIndex];
    
    // Only update the name, preserve everything else (including image if it exists)
    existingModel.name = modelName;
    
    // Update local state first for UI responsiveness
    vehicles.value = updatedVehicles;
    
    // Reset editing state
    const originalModelIndex = editingModel.value;
    editingModel.value = null;
    editModelName.value = '';
    
    // Update Firestore with the correct path: 'vehicles.brands'
    console.log('[VehicleManager] Updating Firestore with the modified vehicles data');
    await updateDoc(doc(projectFirestore, 'assets', 'vehicles'), {
      'vehicles.brands': updatedVehicles
    });
    
    console.log(`[VehicleManager] Successfully saved changes to model: "${modelName}"`);
    
    // Notify parent component
    emit('updated');
  } catch (error) {
    console.error('[VehicleManager] Error saving model changes:', error);
    
    // Attempt to restore previous state if local update was performed but Firestore update failed
    if (previousVehicles) {
      console.log('[VehicleManager] Restoring previous vehicles state after error');
      vehicles.value = previousVehicles;
    }
    
    // Show error to user
    alert(`Error saving model changes: ${error.message || 'Unknown error'}`);
  }
};

const quickBackup = async () => {
  console.log("[VehicleManager] Starting quick backup");
  try {
    // 1. Get current user with more debugging
    const user = store.state.user;
    console.log("[VehicleManager] User from store:", user);
    
    // Check for Firebase auth directly as well
    const firebaseUser = projectAuth.currentUser;
    console.log("[VehicleManager] Firebase current user:", firebaseUser);
    
    // Use either the store user or Firebase user, with a different property check
    const userId = user?.uid || user?.id || firebaseUser?.uid;
    console.log("[VehicleManager] User ID to use:", userId);
    
    if (!userId) {
      console.error("[VehicleManager] No user ID found in either store or Firebase auth");
      alert('You must be logged in to create a backup');
      return;
    }
    
    // 2. Get vehicles data (we already have it loaded)
    console.log("[VehicleManager] Preparing vehicles data for backup");
    const vehiclesData = {
      vehicles: {
        brands: vehicles.value
      }
    };
    
    // 3. Create backup in user's appBackup collection
    const timestamp = new Date().toISOString();
    const backupId = `vehicles_backup_${timestamp.replace(/[:.]/g, '_')}`;
    console.log(`[VehicleManager] Creating backup with ID: ${backupId} for user: ${userId}`);
    
    const userBackupRef = doc(
      projectFirestore, 
      'users', 
      userId, 
      'appBackup', 
      backupId
    );
    
    // 4. Save the backup
    console.log("[VehicleManager] Saving backup to Firestore...");
    await setDoc(userBackupRef, {
      createdAt: timestamp,
      type: 'vehicles',
      data: vehiclesData
    });
    
    console.log("[VehicleManager] Backup successful");
    alert('Backup created successfully in your user profile');
  } catch (error) {
    console.error("[VehicleManager] Backup failed:", error);
    alert('Backup failed: ' + error.message);
  }
};

const manualBackup = async () => {
  console.log("[VehicleManager] Starting manual backup...");
  try {
    // Prompt for the user ID
    const userId = prompt("Enter your user ID for the backup:", "");
    if (!userId) {
      console.log("[VehicleManager] Manual backup cancelled - no user ID provided");
      return;
    }
    
    console.log(`[VehicleManager] Manual backup using user ID: ${userId}`);
    
    // Get vehicles data (we already have it loaded)
    const vehiclesData = {
      vehicles: {
        brands: vehicles.value
      }
    };
    
    // Create backup with timestamp
    const timestamp = new Date().toISOString();
    const backupId = `vehicles_backup_${timestamp.replace(/[:.]/g, '_')}`;
    console.log(`[VehicleManager] Creating backup with ID: ${backupId}`);
    
    // Define the backup document reference
    const userBackupRef = doc(
      projectFirestore, 
      'users', 
      userId, 
      'appBackup', 
      backupId
    );
    
    // Save the backup
    console.log("[VehicleManager] Saving backup to Firestore...");
    await setDoc(userBackupRef, {
      createdAt: timestamp,
      type: 'vehicles',
      data: vehiclesData,
      createdBy: 'manual_backup'
    });
    
    console.log("[VehicleManager] Manual backup successful");
    alert(`Backup created successfully!\n\nLocation: users/${userId}/appBackup/${backupId}`);
  } catch (error) {
    console.error("[VehicleManager] Manual backup failed:", error);
    alert('Manual backup failed: ' + error.message);
  }
};

// Method to handle model image file selection
const handleModelImageChange = (event) => {
  if (event.target.files.length) {
    modelImageFile.value = event.target.files[0];
    console.log(`[VehicleManager] Model image selected: ${modelImageFile.value.name}`);
  }
};

// Method to handle body type image file selection
const handleBodyTypeImageChange = (event) => {
  if (event.target.files.length) {
    bodyTypeImageFile.value = event.target.files[0];
    console.log(`[VehicleManager] Body type image selected: ${bodyTypeImageFile.value.name}`);
  }
};

// Method to upload body type image
const uploadBodyTypeImage = async () => {
  if (!bodyTypeImageFile.value) {
    console.error('[VehicleManager] No body type image file selected');
    return;
  }
  
  if (editingModel.value === null) {
    alert('Please select a model to edit first');
    return;
  }
  
  const brandName = getBrandName(selectedBrand.value);
  const modelName = editModelName.value.trim();
  const bodyType = newBodyType.value.trim();
  
  if (!bodyType) {
    alert('Please enter a body type name first');
    return;
  }
  
  console.log(`[VehicleManager] Uploading body type image for ${brandName} - ${modelName} - ${bodyType}`);
  isUploading.value = true;
  
  try {
    console.log(`[VehicleManager] File name: ${bodyTypeImageFile.value.name}`);
    console.log(`[VehicleManager] File type: ${bodyTypeImageFile.value.type}`);
    console.log(`[VehicleManager] File size: ${bodyTypeImageFile.value.size} bytes`);
    
    // IMPORTANT: Now trying with the direct to root method to bypass any path manipulation
    const uploadPath = `S3 Assets/Manufacturers/${brandName}/${bodyTypeImageFile.value.name}`;
    console.log(`[VehicleManager] Attempting direct root upload with path: ${uploadPath}`);
    
    // Use the direct to root upload function as a last resort
    const uploadResult = await uploadDirectToRoot(bodyTypeImageFile.value, uploadPath);
    
    // Store the URL for later use
    bodyTypeImageUrl.value = uploadResult.url;
    console.log(`[VehicleManager] Body type image upload result URL: ${bodyTypeImageUrl.value}`);
    console.log(`[VehicleManager] Body type image upload result path: ${uploadResult.path}`);
    
    // Ask the user if they want to add the body type now
    if (confirm(`Image uploaded successfully! Would you like to add the "${bodyType}" body type now?`)) {
      // Call addBodyType to create the body type with the image URL
      await addBodyType();
    }
    
    // Reset file input
    bodyTypeImageFile.value = null;
    const fileInput = document.getElementById('body-type-image-input');
    if (fileInput) fileInput.value = '';
  } catch (error) {
    console.error('[VehicleManager] Error uploading body type image:', error);
    alert(`Error uploading body type image: ${error.message || 'Unknown error'}`);
  } finally {
    isUploading.value = false;
  }
};

// Watch for changes in isOpen prop to reload vehicles data
watch(() => props.isOpen, (newVal) => {
  console.log("[VehicleManager] isOpen changed to:", newVal);
  if (newVal) {
    // Check authorization before loading data
    if (isAuthorized.value) {
      loadVehicles().then(() => {
        // After loading vehicles, validate the data structure
        console.log("[VehicleManager] Checking data structure integrity");
        if (!vehicles.value || vehicles.value.length === 0) {
          console.warn("[VehicleManager] No vehicles found or empty vehicles array");
        } else {
          // Log the first brand as a sample
          console.log("[VehicleManager] First brand sample:", 
            vehicles.value[0] ? JSON.stringify(vehicles.value[0], null, 2) : 'None available');
        }
      });
    } else {
      console.log("[VehicleManager] User not authorized to view vehicle manager");
    }
  }
});
</script> 