import store from "../store";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { app } from "../firebase/config";
import { fromUnixTime, format } from 'date-fns';

// Initialize Firestore
const db = getFirestore(app);

export const getBookings = async (month, year) => {
  const userRef = doc(db, "users", store.state.currentUser.uid);
  let bookings = {};
  let otherBookings = {};
  store.state.listBookings = [];
  store.state.customers = [];

  try {
    // Legacy code that should never be removed
    const customerRef = doc(collection(userRef, "bookings"), `${month}${year}`);
    const mainBookingRef = doc(
      collection(userRef, "bookings", "mainBookings", year),
      month
    );
    const otherBookingsRef = doc(
      collection(userRef, "bookings", "otherBookings", year),
      month
    );

    // Fetch legacy bookings
    const customerSnap = await getDoc(customerRef);
    if (customerSnap.exists()) {
      Object.assign(bookings, customerSnap.data());
    }

    // Fetch updated bookings
    const mainBookingSnap = await getDoc(mainBookingRef);
    if (mainBookingSnap.exists()) {
      const tempBookingObject = mainBookingSnap.data();
      for (const [day, dayBookings] of Object.entries(tempBookingObject)) {
        // Iterate through all bookings for this day
        for (const [bookingId, bookingData] of Object.entries(dayBookings)) {
          if (bookingData && bookingData.booking && bookingData.booking.installDetails && bookingData.booking.installDetails.price !== undefined) {
            // Check if it's a v2 booking
            const isBookingVersionV2 = bookingData.booking.installDetails.bookingVersion === "v2";
            if (!isBookingVersionV2) {
              // Convert Unix timestamp to selectedDay, selectedMonth, selectedYear
              const startDate = fromUnixTime(bookingData.booking.installDetails.startTime);
              const endDate = fromUnixTime(bookingData.booking.installDetails.endTime);
              
              bookingData.booking.installDetails.selectedDay = format(startDate, 'dd');
              bookingData.booking.installDetails.selectedMonth = format(startDate, 'MMMM');
              bookingData.booking.installDetails.selectedYear = format(startDate, 'yyyy');
              
              // Convert startTime and endTime to HH:mm format
              bookingData.booking.installDetails.startTime = format(startDate, 'HH:mm');
              bookingData.booking.installDetails.endTime = format(endDate, 'HH:mm');

              // Update version to v2
              bookingData.booking.installDetails.bookingVersion = "v2";
            }
            
            // Create a deep copy of the booking data to preserve structure
            bookings[`${bookingId}`] = {
              ...bookingData,
              booking: {
                ...bookingData.booking,
                sendReview: bookingData.booking.sendReview === true ? true : false,
                customerDetails: { ...bookingData.booking.customerDetails },
                installDetails: { ...bookingData.booking.installDetails },
                vehicleDetails: { ...bookingData.booking.vehicleDetails }
              }
            };
          } else {
            // console.warn(`Corrupted or empty **MAIN** booking found for day ${day} of ${month} ${year}, booking ID ${bookingId} in mainBookings`);
          }
        }
      }
    }

    // Fetch other bookings
    const otherBookingsSnap = await getDoc(otherBookingsRef);
    if (otherBookingsSnap.exists()) {
      const tempOtherBookingObject = otherBookingsSnap.data();
      for (const [day, dayBookings] of Object.entries(tempOtherBookingObject)) {
        // Iterate through all bookings for this day
        for (const [bookingId, bookingData] of Object.entries(dayBookings)) {
          if (bookingData && bookingData.serviceDetails && bookingData.serviceDetails.serviceName) {
            // Check if it's a v2 booking
            const isBookingVersionV2 = bookingData.serviceDetails.bookingVersion === "v2";
            
            if (!isBookingVersionV2) {
              // Convert Unix timestamp to selectedDay, selectedMonth, selectedYear
              const startDate = fromUnixTime(bookingData.serviceDetails.startTime);
              const endDate = fromUnixTime(bookingData.serviceDetails.endTime);
              
              bookingData.serviceDetails.selectedDay = format(startDate, 'dd');
              bookingData.serviceDetails.selectedMonth = format(startDate, 'MMMM');
              bookingData.serviceDetails.selectedYear = format(startDate, 'yyyy');
              
              // Convert startTime and endTime to HH:mm format
              bookingData.serviceDetails.startTime = format(startDate, 'HH:mm');
              bookingData.serviceDetails.endTime = format(endDate, 'HH:mm');

              // Update version to v2
              bookingData.serviceDetails.bookingVersion = "v2";
            }
            
            otherBookings[`${bookingId}`] = bookingData;
          } else {
            // console.warn(`Corrupted or empty **OTHER** booking found for day ${day} of ${month} ${year}, booking ID ${bookingId} in otherBookings`);
          }
        }
      }
    }
  } catch (error) {
    console.log("<<== ERROR IN GET BOOKINGS ==>>", error);
  }

  return { bookings, otherBookings };
};

export const getAllBookings = async () => {
  let bookings = {};
  const bookingsCollectionRef = collection(
    db,
    "users",
    store.state.currentUser.uid,
    "customers"
  );

  try {
    const querySnapshot = await getDocs(bookingsCollectionRef);
    querySnapshot.forEach((doc) => {
      bookings[doc.id] = doc.data();
    });
    store.state.customers = bookings;
  } catch (error) {
    console.log("<<== ERROR IN GET ALL BOOKINGS ==>>", error);
  }
};

export const checkNoShowStatus = async (phoneNumber) => {
  const docRef = doc(
    db,
    "users",
    store.state.currentUser.uid,
    "customers",
    phoneNumber
  );

  try {
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      store.state.customers = { [docSnapshot.id]: docSnapshot.data() };
    } else {
      console.log("No matching record found.");
    }
  } catch (error) {
    console.log("<<== ERROR IN GETTING BOOKING ==>>", error);
  }
};
