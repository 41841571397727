import { ref } from "vue";
import { projectAuth } from "../firebase/config";
import store from "../store/index";
import getUserDocument from "./getUserDocument";
// refs
const user = ref(projectAuth.currentUser);

// auth changes
projectAuth.onAuthStateChanged(async (_user) => {
  user.value = _user;
  if (_user) {
    getUserDocument();

    store.state.currentUser = _user;
    store.state.loggedIn = true;
    store.state.infoLoaded = true;
  }
});
const getUser = () => {
  return { user };
};

export default getUser;
