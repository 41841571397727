<template>
  <div class="video-container">
    <iframe
      :width="width"
      :height="height"
      :src="computedSrc"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  videoId: {
    type: String,
    required: false, // Not required when using playlist
  },
  playlistId: {
    type: String,
    required: false, // Not required if using a single video
  },
  width: {
    type: Number,
    default: 560,
  },
  height: {
    type: Number,
    default: 315,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  mute: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: true,
  },
});

const { videoId, playlistId, width, height, autoplay, mute, controls } =
  toRefs(props);

const computedSrc = computed(() => {
  let src = "";
  const params = ["enablejsapi=1", "origin=" + window.location.origin];

  if (playlistId.value) {
    src = `https://www.youtube.com/embed/videoseries?list=${playlistId.value}`;
  } else if (videoId.value) {
    src = `https://www.youtube.com/embed/${videoId.value}`;
  }

  if (autoplay.value) params.push("autoplay=1");
  if (mute.value) params.push("mute=1");
  if (!controls.value) params.push("controls=0");

  if (params.length) {
    src += src.includes('?') ? '&' : '?';
    src += params.join("&");
  }

  console.log('YouTube embed URL:', src); // For debugging
  return src;
});
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
