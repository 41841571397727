<template>
  <div class="fixed inset-0 grid place-items-center z-50"
    :class="whiteLabelConfig?.theme?.useSecondaryAsBackground ? '' : 'bg-white'"
    :style="[
      whiteLabelConfig?.theme?.useSecondaryAsBackground 
        ? { backgroundColor: whiteLabelConfig.theme.colors.secondary }
        : {}
    ]">
    <div class="grid place-items-center" :class="whiteLabelConfig?.theme?.useSecondaryAsBackground ? 'text-white' : 'text-gray-900'">
      <div v-if="!isLoading">
        <img
          v-if="whiteLabelConfig?.theme?.logo?.default"
          :src="whiteLabelConfig.theme.logo.default"
          alt="Company Logo"
          :class="[
            whiteLabelConfig.theme.logo.size?.desktop || 'h-20',
            'rounded mb-10 object-contain'
          ]"
        />
        <img
          v-else
          src="../../assets/TB Logo.png"
          alt="TB Logo"
          class="w-20 h-20 rounded mb-10"
        />
      </div>
      <div v-else class="w-20 h-20 mb-10 bg-gray-800 rounded animate-pulse"></div>
      
      <!-- Error Display -->
      <div v-if="error" class="mb-10 text-center">
        <p class="text-red-500 text-lg mb-4">{{ error }}</p>
        <button 
          @click="retryValidation"
          class="px-6 py-2 bg-yellow-500 text-black rounded-lg font-medium hover:bg-yellow-400 transition-colors"
        >
          Retry
        </button>
      </div>
      
      <!-- Loading Animation -->
      <div v-else
      class="grid place-items-center"
      >
        <svg
          class="animate-spin h-20 w-20 "
          :class="whiteLabelConfig?.theme?.useSecondaryAsBackground ? 'text-white' : 'text-gray-900'"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <p class="text-center mt-10 font-bold text-xl" :class="whiteLabelConfig?.theme?.useSecondaryAsBackground ? 'text-white' : 'text-gray-900'">
          {{ isValidating ? 'Validating your account...' : 'Loading... please wait.' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useWhiteLabel from "../../composables/useWhiteLabel";
import store from "../../store";
import { doc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';

const router = useRouter();
const { whiteLabelConfig, isLoading } = useWhiteLabel();
const isValidating = ref(true);
const error = ref(null);

const validateAccount = async () => {
  isValidating.value = true;
  error.value = null;

  try {
    // Check if user is authenticated
    if (!store.state.currentUser?.uid) {
      error.value = 'User not authenticated';
      isValidating.value = false;
      return;
    }

    const userRef = doc(projectFirestore, "users", store.state.currentUser.uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      router.push({ name: "BookDemo" });
      return;
    }

    const userData = userDoc.data();
    
    // Update store state with user data
    store.state.hasSubscribed = userData.subscription || false;
    store.state.userConfig.configComplete = userData.configComplete || false;
    store.state.demoBooked = userData.demoBooked || false;

    // Check all required states in order of priority
    if (!userData.demoBooked) {
      console.log("Demo not booked, redirecting to BookDemo");
      router.push({ name: "BookDemo" });
      return;
    }
    
    if (!userData.subscription) {
      console.log("No subscription, redirecting to Pricing");
      router.push({ name: "Pricing" });
      return;
    }
    
    if (!userData.configComplete) {
      console.log("Config not complete, redirecting to Apply");
      router.push({ name: "Apply" });
      return;
    }

    isValidating.value = false;
  } catch (err) {
    console.error('Validation error:', err);
    error.value = 'An error occurred during account validation. Please try again.';
    isValidating.value = false;
  }
};

const retryValidation = () => {
  validateAccount();
};

onMounted(() => {
  validateAccount();
});
</script>
